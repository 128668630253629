import React from "react";

import ScrollToTopOnMount from './components/ScrollToTopOnMount';
import ScrollToTop from "react-scroll-to-top";


//images
import av3d from './images/avatar3d/avatar3d.jpg';
import elproma from './images/elproma/elproma_cover.jpg';
import instaproj from './images/instaproject.jpg';
import atraktor from './images/attractors/attractor.jpg';
import plotoptix from './images/PlotOptix.jpg';
import posters from './images/posters/posters.jpg';
import moon from './images/moon/moon.jpg';
import noise from './images/noise/sculpture_cover.jpg';
import yacht from './images/yachts/yis_cover.jpg';
import simplex from './images/simplex.jpg';
import wormance from './images/wormances/wormance_cover.jpg';
import radio from './images/radio/radio_cover.jpg';
import pose from './images/pose/pose_cover.jpg';

import Footer from './components/Footer/Footer';

import { Link, useNavigate } from 'react-router-dom';

const Work = () => {
  
        const navigate = useNavigate();
        return (
         
         <div> 

            <ScrollToTopOnMount />
            <ScrollToTop smooth />

           <div className="beginning"> 
            <p className="title">Our recent projects</p>
           </div>

           <div className="infoframe">
              <div className="infotext"> 
          
                    <p>
                      We are interested in everything connected to visual content creation, processing, recognition, and analysis.
                      Primarily, we are working with 3D ray-tracing, machine learning, and WebGL techniques.
                    </p>

                    <div> 
                      Illustrations available for purchase:
                      <span style={{fontSize:"smaller"}}>
                        <ul>
                          <li><a href="https://www.balthasart.com/en/artist/dorota?artistId=18406&previewToken=hGKRISIAERP8f1TUbuY94iqupR7D4MMhZDp5QrWUIi4%3D.eyJpZCI6MTg0MDZ9" target="_blanc">Balthasar gallery</a>.
                          </li>
                        </ul> 
                      </span>
                      ART exhibitions / fairs in previous years:
                      <span style={{fontSize:"smaller"}}>
                        <ul>
                          <li>Selected posters, not published before on the real life physical paper, were available for purchase at <b>Poster Fair</b> in Warsaw, on 16/09/2023.
                          </li>
                          <li>
                            <a href="https://www.behance.net/gallery/172950163/17-seconds" target="_blanc">"17 seconds"</a> - illustrations &amp; poems, a book by Dorota on the exhibition at Terminal Kultury, Warsaw.
                            Opening on 26/06/2023. 
                          </li>
                          <li>Attractors, The Moon and Wormances in the <b>Tempelhof Airport, Berlin</b>. 2022.</li>
                          <li>Wormances in the <b>Absent Gallery</b>. 2021.</li>
                      </ul> 
                      </span>
                    </div>

                    <p>
                      And <b>a selection of work projects</b>:
                    </p> 
                       
              </div>
            </div> 

            <div className="row-wrapper"> 
                
              <div className="pcolumn">
                <div className="linkimg">
                
                  <Link to="/project/avatar3D" onClick={()=>navigate("/project/avatar3D")}>
                    <img src={av3d} alt="3D model of avatar"></img>  
                  </Link> 
                  <div>
                    <p>Speaking 3D avatar</p>
                  </div>

                  <Link to="/project/elproma" onClick={()=>navigate("/project/elproma")}>
                    <img src={elproma} alt="Elproma posters"></img>   
                  </Link> 
                  <div>
                    <p>Project for <a href="http://www.elproma.com.pl/">Elproma Electronics</a></p>
                  </div>

                  <Link to="/project/noise" onClick={()=>navigate("/project/noise")}>
                    <img src={noise} alt="Noise sculptures"></img>
                  </Link>
                  <div>
                    <p>Noise sculptures</p>
                  </div>

                  <Link to="/project/moon" onClick={()=>navigate("/project/moon")}>
                    <img src={moon} alt="Making of the Moon"></img>   
                  </Link>
                  <div>
                    <p>Making of the Moon</p>
                  </div>

                  <Link to="/project/datavisualization" onClick={()=>navigate("/project/datavisualization")}>
                    <img src={instaproj} alt="Instagram Scraping"></img> 
                  </Link> 
                  <div>
                    <p>Scraping Instagram</p>
                  </div>
               
                  <Link to="/project/posters" onClick={()=>navigate("/project/posters")}>
                    <img src={posters} alt="posters"></img>                 
                  </Link>
                  <div>
                    <p>Posters</p>
                  </div>

                  <Link to="/project/attractors" onClick={()=>navigate("/project/attractors")}>
                    <img src={atraktor} alt="Strange Attractors"></img>                
                  </Link>
                  <div>
                    <p>Attractors</p>
                  </div>

                </div>
              </div> 
               

              <div className="pcolumn">
                <div className="linkimg">
                  <Link to="/project/radio" onClick={()=>navigate("/project/radio")}>
                    <img src={radio} alt="Field coverage studies"></img>              
                  </Link>
                  <div>
                    <p>Field coverage studies</p>
                  </div>

                  <Link to="/project/pose" onClick={()=>navigate("/project/pose")}>
                    <img src={pose} alt="Pose reconstruction"></img>           
                  </Link>
                  <div>
                    <p>Human pose reconstruction</p>
                  </div>

                  <Link to="/project/wormances" onClick={()=>navigate("/project/wormances")}>
                    <img src={wormance} alt="Wormances"></img>                
                  </Link>
                  <div>
                    <p>Wormances - generative creatures</p>
                  </div>
                  
                  <Link to="/project/yachts" onClick={()=>navigate("/project/yachts")}>
                    <img src={yacht} alt="Superyacht visualization"></img>        
                  </Link>
                  <div>
                    <p>Superyacht visualization</p>
                  </div>

                  <Link to="/project/generativecontent" onClick={()=>navigate("/project/generativecontent")}>                    
                    <img src={simplex} alt="Generative Content"></img>  
                  </Link>
                  <div>
                    <p>Generative Content</p>
                  </div>

                  <Link to="/plotoptix" onClick={()=>navigate("/plotoptix")}>
                    <img src={plotoptix} alt="PlotOptiX - ray tracing in Python"></img>
                  </Link> 
                  <div>
                    <p>PlotOptiX</p>
                  </div>

                </div>
              </div>                      
            </div> 

          <Footer />
           
         </div>
        );
  }


export default Work;